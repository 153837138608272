import React, { useEffect, useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import SimpleSlider from 'components/carousel/simple'
import SimpleAccordion from 'components/accordion/simple'
import SolidDropdown from 'components/dropdown/solid'
import I18nImage from 'components/gallery/i18nImage'

import {
  applicationList,
  audienceAnalyticsFeatures,
  streamChinaAccessFeatures,
  streamToChinaAdvantages,
  ctaClickIds
} from '/static/data/solution.static'
import { chinaCDNList } from '/static/data/cdnBrand.static'

import * as streamChinaAccessStyles from 'styles/streamChinaAccess.module.css'

const CtaButtons = ({ clickIdIndex: [first, second] }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row gap-3 my-1">
      <Link to="/sign-up" id={ctaClickIds[first]} className="btn btn-bold btn-orange inline-block w-[208px]">
        {t('button.freeTrial.0')}
      </Link>
      <Link
        to="/lp/get-a-demo/"
        id={ctaClickIds[second]}
        className="btn btn-bold btn-ghost btn-orange inline-block w-[208px]"
      >
        {t('button.getDemo.0')}
      </Link>
    </div>
  )
}

const VideoStreamChinaPage = () => {
  const { t } = useTranslation()
  const APPLICATION_LIST = applicationList()
  const APPLICATION_SELECT = APPLICATION_LIST.map((item, index) => {
    return { name: t('videoStreamChinaPage.section3.title', { text: t(item.title) }), value: index, type: item.type }
  })
  const AUDIENCE_ANALYTICS_FEATURES = audienceAnalyticsFeatures()
  const STREAM_CHINA_ACCESS_FEATURE = streamChinaAccessFeatures()
  const STREAM_TO_CHINA_ADVANTAGES = streamToChinaAdvantages()
  const FEATURES_SLIDER_SETTINGS = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 500,
    swipe: false,
    touchMove: false,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    beforeChange: (current, next) => {
      setSelectedFeatureIndex(next)
    }
  }
  const [selectedAppIndex, setSelectedAppIndex] = useState(0)
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0)
  const sliderRef = useRef(null)
  const clickApplicationButton = (event) => {
    setSelectedAppIndex(+event.target.value)
  }
  const clickFeatureButton = (value) => {
    setSelectedFeatureIndex(value)
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(selectedFeatureIndex)
  }, [selectedFeatureIndex])

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('videoStreamChinaPage.meta.title'),
            metaDescription: t('videoStreamChinaPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/solutions/video-stream-china/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col-reverse lg:flex-row gap-5 min-h-[80vh] py-5 lg:py-24">
          <div className="flex-1">
            <h1 className="font-normal mb-5 text-center lg:text-left text-primary-400 text-lg">
              {t('videoStreamChinaPage.section1.subtitle')}
            </h1>
            <h2 className="text-center lg:text-left text-primary-500 text-4xl lg:text-5xl">
              {t('videoStreamChinaPage.section1.title')}
            </h2>
            <p className="py-10 text-gray-500">
              {t('videoStreamChinaPage.section1.description.0')}
              <br />
              <br />
              {t('videoStreamChinaPage.section1.description.1')}
            </p>
            <CtaButtons clickIdIndex={[0, 1]} />
          </div>
          <div className="flex flex-1 items-center justify-center">
            <I18nImage
              src="https://static.mlytics.com/images/website/unlock_china_online_audience.png"
              alt={t('videoStreamChinaPage.section1.subtitle')}
              style={{ height: 'auto', maxWidth: '100%' }}
            />
          </div>
        </section>
        <section className="grid grid-cols-1 lg:grid-cols-2">
          <SimpleSlider className="slider-h-full fade-slide" ref={sliderRef} settings={FEATURES_SLIDER_SETTINGS}>
            {STREAM_TO_CHINA_ADVANTAGES.map((item, index) => (
              <div key={index} className="h-full">
                <div
                  className={`${streamChinaAccessStyles.slide} ${
                    index === selectedFeatureIndex ? streamChinaAccessStyles.active : ''
                  }`}
                  style={{ backgroundImage: `url(${item.image})` }}
                />
              </div>
            ))}
          </SimpleSlider>
          <div className="bg-blue-300 px-4 lg:px-12 py-14">
            <h2 className="mb-4 text-center lg:text-left leading-tight text-white text-2xl lg:text-4.5xl">
              {t('videoStreamChinaPage.section2.title')}
            </h2>
            <p className="text-center lg:text-left pb-8 text-white">{t('videoStreamChinaPage.section2.description')}</p>
            <ul className={streamChinaAccessStyles.featureList}>
              {STREAM_TO_CHINA_ADVANTAGES.map((item, index) => (
                <li
                  id={item.clickId}
                  key={index}
                  className={`${streamChinaAccessStyles.buttonLike} ${
                    index === selectedFeatureIndex ? streamChinaAccessStyles.active : ''
                  }`}
                  onClick={() => clickFeatureButton(index)}
                >
                  <div className="flex items-center">
                    <div className="shrink-0 mr-4" style={{ flexBasis: 64 }}>
                      <img src={item.icon} height="64" width="64" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <h3 className="text-base lg:text-2xl">{t(item.title)}</h3>
                      <p className="hidden lg:block">{t(item.content)}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="container py-16">
          <h2 className="mb-5 text-center text-primary-500 text-2.5xl">{t('videoStreamChinaPage.section6.title')}</h2>
          <div className="flex flex-wrap items-center justify-center">
            {chinaCDNList.map((item) => (
              <img key={item.alt} src={item.src} alt={item.alt} height="80" width="160" />
            ))}
          </div>
        </section>
        <section className="flex flex-col lg:flex-row">
          <div className={`flex-1 ${streamChinaAccessStyles.applicationButtonGroup}`}>
            {APPLICATION_LIST.map((item, index) => (
              <button
                id={item.clickIds[0]}
                className={index === selectedAppIndex ? streamChinaAccessStyles.active : ''}
                key={index}
                value={index}
                style={{ backgroundImage: `url(${item.bgImage})` }}
                onClick={clickApplicationButton}
              >
                <span>{t(item.title)}</span>
              </button>
            ))}
          </div>
          <div className="flex flex-col flex-1">
            <div className="bg-blue-200 flex-1 px-4 py-10">
              <div className="mx-auto max-w-[620px]">
                <div className="text-center">
                  <h2 className="bg-white hidden lg:inline-block px-4 py-1 rounded-lg text-blue-200 text-3xl">
                    {t('videoStreamChinaPage.section3.title', { text: t(APPLICATION_LIST[selectedAppIndex].title) })}
                  </h2>
                  <div className="block lg:hidden">
                    <h3 className="text-white mb-2">{t('videoStreamChinaPage.section3.mobileTitle')}</h3>
                    <SolidDropdown
                      currentOption={selectedAppIndex}
                      options={APPLICATION_SELECT}
                      changeMenuState={(event) => clickApplicationButton(event)}
                    />
                  </div>
                  <img
                    src={APPLICATION_LIST[selectedAppIndex].image}
                    className="mx-auto my-10"
                    height="180"
                    width="580"
                  />
                </div>
                <ul className="list-disc pl-5 text-white">
                  {APPLICATION_LIST[selectedAppIndex].descriptions.map((desc) => (
                    <li key={desc}>{t(desc)}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex" style={{ height: 80 }}>
              <Link
                id={APPLICATION_LIST[selectedAppIndex].clickIds[1]}
                to="/sign-up"
                className="bg-secondary-600 flex-1 font-bold inline-flex justify-center items-center text-white tracking-wider duration-200 ease-linear hover:opacity-80"
              >
                {t('button.freeTrial.0')}
              </Link>
              <Link
                id={APPLICATION_LIST[selectedAppIndex].clickIds[2]}
                to="/lp/get-a-demo/"
                className="border-2 border-secondary-600 flex-1 font-bold inline-flex justify-center items-center text-secondary-600 tracking-wider duration-200 ease-linear hover:opacity-80"
              >
                {t('button.getDemo.0')}
              </Link>
            </div>
          </div>
        </section>
        <section>
          <h2 className="py-10 lg:py-20 text-center text-primary-500 text-3xl lg:text-5xl">
            {t('videoStreamChinaPage.section4.title')}
          </h2>
          <div className="container flex flex-col lg:flex-row gap-10">
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">
                {t('videoStreamChinaPage.section4.list1.0.subtitle')}
              </div>
              <h3 className="text-xl lg:text-3xl">{t('videoStreamChinaPage.section4.list1.0.title')}</h3>
              <p className="pb-7 pt-2 text-gray-500">
                <Trans i18nKey="videoStreamChinaPage.section4.list1.0.description">
                  <Link to="/video-stream/audience-analytics/" className="text-secondary-600 underline" />
                </Trans>
              </p>
              <div className="mb-8">
                <SimpleAccordion list={AUDIENCE_ANALYTICS_FEATURES} />
              </div>
              <CtaButtons clickIdIndex={[2, 3]} />
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <div className="text-center">
                <I18nImage
                  src="https://static.mlytics.com/images/website/stream_viewer_experience_analytics.png"
                  alt={t('videoStreamChinaPage.section4.list1.0.subtitle')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
                <a
                  id="2cn_feature_01_test"
                  href="https://hubble.mlytics.com/basic_test?region=china"
                  className="btn btn-blue mt-7"
                  rel="noopener noreferrer"
                >
                  {t('videoStreamChinaPage.section4.list1.0.button')}
                </a>
              </div>
            </div>
          </div>
          <div className="container flex flex-col lg:flex-row-reverse gap-10 py-20">
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">
                {t('videoStreamChinaPage.section4.list1.1.subtitle')}
              </div>
              <h3 className="text-xl lg:text-3xl">{t('videoStreamChinaPage.section4.list1.1.title')}</h3>
              <p className="pb-7 pt-2 text-gray-500">
                <Trans i18nKey="videoStreamChinaPage.section4.list1.1.description">
                  <Link to="/video-stream/multi-cdn-management/" className="text-secondary-600 underline" />
                </Trans>
              </p>
              <CtaButtons clickIdIndex={[4, 5]} />
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <I18nImage
                src="https://static.mlytics.com/images/website/fit_your_video_stream_in_china.png"
                alt={t('videoStreamChinaPage.section4.list1.1.subtitle')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
          <div className="container flex flex-col lg:flex-row gap-10 py-20">
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">
                {t('videoStreamChinaPage.section4.list1.2.subtitle')}
              </div>
              <h3 className="text-xl lg:text-3xl">{t('videoStreamChinaPage.section4.list1.2.title')}</h3>
              <p className="pb-7 pt-2 text-gray-500">
                <Trans i18nKey="videoStreamChinaPage.section4.list1.2.description">
                  <Link to="/video-stream/instream-switching/" className="text-secondary-600 underline" />
                </Trans>
              </p>
              <CtaButtons clickIdIndex={[6, 7]} />
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <I18nImage
                src="https://static.mlytics.com/images/website/handle_traffic_rebuffering_in_china.png"
                alt={t('videoStreamChinaPage.section4.list1.2.subtitle')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
          <div className="container flex flex-col lg:flex-row-reverse gap-10 py-20">
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">
                {t('videoStreamChinaPage.section4.list1.3.subtitle')}
              </div>
              <h3 className="text-xl lg:text-3xl">{t('videoStreamChinaPage.section4.list1.3.title')}</h3>
              <p className="pb-7 pt-2 text-gray-500">{t('videoStreamChinaPage.section4.list1.3.description')}</p>
              <CtaButtons clickIdIndex={[8, 9]} />
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <I18nImage
                src="https://static.mlytics.com/images/website/support_your_stream_more_accessible_in_china.png"
                alt={t('videoStreamChinaPage.section4.list1.3.subtitle')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </div>
          </div>
        </section>
        <section className="bg-primary-500/5 py-20">
          <div className="container grid grid-cols-1 lg:grid-cols-3 gap-5">
            {STREAM_CHINA_ACCESS_FEATURE.map((feature) => (
              <div key={feature.title} className="text-center">
                <img src={feature.imageSrc} alt={feature.title} className="mx-auto" />
                <h2 className="text-primary-500 text-2xl">{t(feature.title)}</h2>
                <p className="my-5 text-gray-700">{t(feature.content)}</p>
              </div>
            ))}
          </div>
        </section>
        <GotoDemoHero title="videoStreamChinaPage.section7.title" clickIds={[ctaClickIds[10], ctaClickIds[11]]} />
      </React.Fragment>
    </Layout>
  )
}

CtaButtons.propTypes = {
  firstButtonLink: PropTypes.string,
  clickIdIndex: PropTypes.array
}

export default VideoStreamChinaPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
